export const surveyDataMock = [
    {
        response: 'Yes',
        count: 20,
    },
    {
        response: 'No',
        count: 1,
    },
    {
        response: 'Maybe',
        count: 9,
    },
    {
        response: "I don't know",
        count: 0,
    },
]
