export const localeText = {
    // for filter panel
    page: 'Page',
    more: 'Plus',
    to: 'au',
    of: 'de',
    next: 'Suivant',
    last: 'Dérnier',
    first: 'Premier',
    previous: 'Précedant',
    loadingOoo: 'Chargement des données...',

    // for set filter
    selectAll: 'Sélectionner tous',
    searchOoo: 'Rechercher...',
    blanks: 'Vide',

    // Number Filter & Text Filter
    filterOoo: 'Filtrer...',
    applyFilter: 'Appliquer...',
    cancelFilter: 'Annuler',
    equals: 'Egale',
    notEquals: 'Pas égal',
    notEqual: 'Pas égal',
    blank: 'Vide',
    notBlank: 'Pas vide',
    empty: 'Choisissez',

    // for number filter
    lessThan: 'Moins que',
    greaterThan: 'Plus que',
    lessThanOrEqual: 'Moins que ou égal',
    greaterThanOrEqual: 'Plus que ou égal',
    inRange: 'Dans la gamme',
    inRangeStart: 'De',
    inRangeEnd: 'À',

    // for text filter
    contains: 'Contient',
    notContains: 'Ne contient pas',
    startsWith: 'Commence par',
    endsWith: 'Términe par',

    // filter conditions
    andCondition: 'ET',
    orCondition: 'OU',

    // the header of the default group column
    group: 'Groupe',

    // tool panel
    columns: 'Colonnes',
    filters: 'Filtres',
    rowGroupColumns: 'Colonnes Pivot',
    rowGroupColumnsEmptyMessage: 'glissez des colonnes ici pour les grouper',
    valueColumns: 'Colonnes de valeur',
    pivotMode: 'Mode Pivot',
    groups: 'Groupes',
    values: 'Valeurs',
    pivots: 'Pivots',
    valueColumnsEmptyMessage: 'glissez des colonnes ici pour les agréger',
    pivotColumnsEmptyMessage: 'glissez des colonnes ici pour les pivoter',
    toolPanelButton: "Ouvrir panneau d'outils",

    // other
    noRowsToShow: 'Pas de données',

    // enterprise menu
    pinColumn: 'Epingler la colonne',
    valueAggregation: 'Agrégation de valeur',
    autosizeThiscolumn: 'Auto largeur de cette colonne',
    autosizeAllColumns: 'Auto largeur de toutes les colonnes',
    groupBy: 'Grouper par',
    ungroupBy: 'Dégrouper par',
    resetColumns: 'Réinitialiser les colonnes',
    expandAll: 'Développer groupes',
    collapseAll: 'Réduire groupes',
    toolPanel: "Panneau d'outils",
    export: 'Exporter',
    csvExport: 'CSV (.csv)',
    excelExport: 'Excel (.xlsx)',

    // enterprise menu pinning
    pinLeft: '<< Épingle gauche',
    pinRight: 'Épingle droit >>',
    noPin: 'Sans épingle',

    // enterprise menu aggregation and status bar
    sum: 'Somme',
    min: 'Min',
    max: 'Max',
    none: 'Pas',
    count: 'Compte',
    average: 'Moyen',
    filteredRows: 'Nombre de lignes filtrées',
    selectedRows: 'Nombre de lignes selectionnées',
    totalRows: 'Nombre de lignes total',
    totalAndFilteredRows: 'Nombre de lignes filtré du total',

    // standard menu
    copy: 'Copier',
    copyWithHeaders: "Copier avec l'en-tête",
    ctrlC: 'Ctrl + C',
    paste: 'Coller',
    ctrlV: 'Ctrl + V',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Plage de graphique',

    columnChart: 'Colonnes',
    groupedColumn: 'Grouped',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',

    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',

    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',

    line: 'Lignes',

    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',

    areaChart: 'Surface',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',

    histogramChart: 'Histogramme',

    // Charts
    pivotChartTitle: 'Pivot Chart',
    rangeChartTitle: 'Range Chart',
    settings: 'Settings',
    data: 'Data',
    format: 'Format',
    categories: 'Categories',
    defaultCategory: '(None)',
    series: 'Series',
    xyValues: 'X Y Values',
    paired: 'Paired Mode',
    axis: 'Axis',
    navigator: 'Navigator',
    color: 'Color',
    thickness: 'Thickness',
    xType: 'X Type',
    automatic: 'Automatic',
    category: 'Category',
    number: 'Number',
    time: 'Time',
    xRotation: 'X Rotation',
    yRotation: 'Y Rotation',
    ticks: 'Ticks',
    width: 'Width',
    height: 'Height',
    length: 'Length',
    padding: 'Padding',
    spacing: 'Spacing',
    chart: 'Chart',
    title: 'Title',
    titlePlaceholder: 'Chart title - double click to edit',
    background: 'Background',
    font: 'Font',
    top: 'Top',
    right: 'Right',
    bottom: 'Bottom',
    left: 'Left',
    labels: 'Labels',
    size: 'Size',
    minSize: 'Minimum Size',
    maxSize: 'Maximum Size',
    legend: 'Legend',
    position: 'Position',
    markerSize: 'Marker Size',
    markerStroke: 'Marker Stroke',
    markerPadding: 'Marker Padding',
    itemSpacing: 'Item Spacing',
    itemPaddingX: 'Item Padding X',
    itemPaddingY: 'Item Padding Y',
    layoutHorizontalSpacing: 'Horizontal Spacing',
    layoutVerticalSpacing: 'Vertical Spacing',
    strokeWidth: 'Stroke Width',
    offset: 'Offset',
    offsets: 'Offsets',
    tooltips: 'Tooltips',
    callout: 'Callout',
    markers: 'Markers',
    shadow: 'Shadow',
    blur: 'Blur',
    xOffset: 'X Offset',
    yOffset: 'Y Offset',
    lineWidth: 'Line Width',
    normal: 'Normal',
    bold: 'Bold',
    italic: 'Italic',
    boldItalic: 'Bold Italic',
    predefined: 'Predefined',
    fillOpacity: 'Fill Opacity',
    strokeOpacity: 'Line Opacity',
    histogramBinCount: 'Bin count',
    columnGroup: 'Column',
    barGroup: 'Bar',
    pieGroup: 'Pie',
    lineGroup: 'Line',
    scatterGroup: 'X Y (Scatter)',
    areaGroup: 'Area',
    histogramGroup: 'Histogram',
    groupedColumnTooltip: 'Grouped',
    stackedColumnTooltip: 'Stacked',
    normalizedColumnTooltip: '100% Stacked',
    groupedBarTooltip: 'Grouped',
    stackedBarTooltip: 'Stacked',
    normalizedBarTooltip: '100% Stacked',
    pieTooltip: 'Pie',
    doughnutTooltip: 'Doughnut',
    lineTooltip: 'Line',
    groupedAreaTooltip: 'Area',
    stackedAreaTooltip: 'Stacked',
    normalizedAreaTooltip: '100% Stacked',
    scatterTooltip: 'Scatter',
    bubbleTooltip: 'Bubble',
    histogramTooltip: 'Histogram',
    noDataToChart: 'No data available to be charted.',
    pivotChartRequiresPivotMode: 'Pivot Chart requires Pivot Mode enabled.',
    chartSettingsToolbarTooltip: 'Menu',
    chartLinkToolbarTooltip: 'Linked to Grid',
    chartUnlinkToolbarTooltip: 'Unlinked from Grid',
    chartDownloadToolbarTooltip: 'Download Chart',
}
